import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'
import { isFile } from 'utils/miscHelper'
export const getSurvey = async (data) => {
  try {
    const response = await client.post('/surveys/get-survey', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};
 

export const deleteSurveys = async (data) => {
  try {
    const response = await client.post('/surveys/delete-surveys', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const activeSurveys = async (data) => {
  try {
    const response = await client.post('/surveys/active-surveys', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const inActiveSurveys  = async (data) => {
  try {
    const response = await client.post('/surveys/inActive-surveys', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCmCareCampaignById = async (data) => {
  try {
    const response = await client.post('/surveys/getCmCareCampaignById', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};





export const surveySave = async (data) => {
  try {
    const formData = new FormData();
    formData.append("survey", JSON.stringify(createBy(data)))

    if (isFile(data?.surveyImageUrl)) {
      formData.append("surveyImageUrl", data?.surveyImageUrl)
    }
    if (isFile(data?.surveyQuestion[0].surveyQuestionAnswer[0].answerImgUrl)) {
      formData.append("answerImgUrl", data?.surveyQuestion[0].surveyQuestionAnswer[0].answerImgUrl)
    }
    const response = await client.post('/surveys/save', formData);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};


export const getSurveyById = async (data) => {
  try {
    const response = await client.post('/surveys/get-survey-detail', data);
    return response.data;
  } catch (error) {
    console.log(error) 
    throw error;
  }
};




export const getParameterGroup = async (data) => {
  try {
    const response = await client.post('/surveys/paramter-group', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getParameterInterest = async (data) => {
  try {
    const response = await client.post('/surveys/paramter-interest', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};



export const getParameterAttribute1 = async (data) => {
  try {
    const response = await client.post('/surveys/paramter-attribute1', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};


export const survey = async (data) => {
  try {
    console.log('survey request=',data);
    const response = await client.post('/surveys/survey', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};


export const getLovSurvey = async (data) => {
  try {
    console.log('getLovSurvey request=',data);
    const response = await client.post('/surveys/get-lov-survey', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};


export const Constants= {
    SURVEY_PAGE: {
        START: "START",
        END: "END",
        SURVEY: "SURVEY"
    },
    SURVEY_TYPE: {
        TEXT: "TEXT",
        DATE: "DATE",
        IMAGE: "IMAGE",
        SCALE: "SCALE",
        TABLE: "TABLE",
        TABLE_MULTI: "TABLE_MULTI",
        RATING: "RATING",
        RANKING: "RANKING",
        DROPDOWN: "DROPDOWN",
        SELECT_ONE: "SELECT_ONE",
        MULTI_SELECT: "MULTI_SELECT"
    },
    DIRECTION: {
        NEXT: 1,
        BACK: -1,
    },
    DESCRIPTIONS: {
        TEXT_PLACEHOLDER: "พิมพ์ข้อความที่นี่", //1
        SEARCH_PLACEHOLDER: "พิมพ์เพื่อค้นหา", //2
        IMAGE_PLACEHOLDER: "คำบรรยาย", //3
        RATING_PLACEHOLDER: "บอกเราหน่อยว่าทำไม", //4
        RATING_REQUIRE_COMMENT: "กรุณาแสดงความคิดเห็น", //5
        ATTACH_IMAGE_TEXT: "แนบรูปภาพ", //6
        SUBMIT_TEXT: "Submit", //7
        SAVE_DUPLICATED: "คุณได้ตอบแบบสอบถามนี้ไปแล้ว", //8
        SAVE_INCOMPLETE: "บันทึกไม่สำเร็จ", //9
    },
    FLAG_YES_NO: {
        YES: 'Y',
        NO: 'N' 
    },
    SURVEY_STATUS: {
        COMPLETE: 'COMPLETE',
        INCOMPLETE: 'INCOMPLETE',
    },
    SAVE_MODE: {
        CREATE: "CREATE",
        SAVE: "SAVE",
        EDIT: "EDIT",
    },
    QUESTION_LEVEL: {
        ONE: 1,
        TWO: 2,
    },
    ELEMENT_TYPE: {
        TEXT: "TEXT",
        IMAGE: "IMAGE",
        SLIDER: "SLIDER",
    },
    DATE_FORMATS: {
        DD_MM_YYYY: "DD/MM/YYYY",
    },
    SURVEY_RESPONSE_MESSAGE: {
        CUSTOMER_SURVEY_SAVE_DUPLICATED: "CUSTOMER_SURVEY_SAVE_DUPLICATED",
    }
}

export const PermissionResponses = {
    CLOSE_BY_REWARD: {
        STATUS: 'CLOSE_BY_REWARD',
        MESSAGE: 'ไม่สามารถทำแบบสอบถามได้',
    },
    SURVEY_OPEN: {
        STATUS: 'SURVEY_OPEN',
        MESSAGE: 'เริ่มแบบสอบถาม',
    },
    CLAIM_REWARD: {
        STATUS: 'CLAIM_REWARD',
        MESSAGE: 'กดรับรางวัล',
    },
    SURVEY_END: {
        STATUS: 'SURVEY_END',
        MESSAGE: 'คุณทำแบบสอบถามนี้ไปแล้ว',
    },
    ERROR_SURVEY_NOT_FOUND: {
        STATUS: 'ERROR_SURVEY_NOT_FOUND',
        MESSAGE: 'ไม่พบแบบสอบถาม',
    },
    ERROR: {
        STATUS: 'ERROR',
        MESSAGE: 'เกิดข้อผิดพลาด',
    }
}